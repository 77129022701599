import React, { useState, useCallback } from 'react';
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { ArrowUpRight, ArrowDownRight, DollarSign, Users, Building, BarChart } from 'lucide-react';

// Utility functions
const formatCurrency = (value) => {
  if (value >= 1000000) {
    return `$${(value / 1000000).toFixed(1)}M`;
  } else if (value >= 1000) {
    return `$${(value / 1000).toFixed(1)}K`;
  }
  return `$${value.toFixed(2)}`;
};

const ValuationGoals = ({ currentMetrics }) => {
  const [targetValuation, setTargetValuation] = useState(3000000);
  const [timeframe, setTimeframe] = useState(24);
  const [selectedMultiple, setSelectedMultiple] = useState(5);

  const current = {
    revenue: currentMetrics?.total_revenue || 1600000,
    ebitdaMargin: currentMetrics?.ebitda_percentage || 25,
    ebitda: (currentMetrics?.total_revenue || 1600000) * (currentMetrics?.ebitda_percentage || 25) / 100,
    staffCost: currentMetrics?.staff_payroll_percentage || 20,
    doctorPay: currentMetrics?.doctor_payroll_percentage || 17.5,
    rentCost: currentMetrics?.rent_percentage || 5,
    grossMargin: currentMetrics?.gross_profit_percentage || 70,
  };

  // Your calculation functions remain the same
  const calculateRequiredMetrics = useCallback(() => {
    const targetEBITDA = targetValuation / selectedMultiple;
    const requiredGrowthRate = (Math.pow(targetEBITDA / current.ebitda, 1 / (timeframe / 12)) - 1) * 100;

    const monthlyData = [];
    for (let i = 0; i <= timeframe; i++) {
      const growthFactor = Math.pow(1 + requiredGrowthRate / 100, i / 12);
      const projectedEBITDA = current.ebitda * growthFactor;
      const projectedRevenue = projectedEBITDA / (current.ebitdaMargin / 100);

      monthlyData.push({
        month: i,
        EBITDA: Math.round(projectedEBITDA),
        Revenue: Math.round(projectedRevenue),
        Valuation: Math.round(projectedEBITDA * selectedMultiple)
      });
    }

    return {
      requiredGrowthRate,
      monthlyData,
      targetEBITDA,
      requiredRevenue: targetEBITDA / (current.ebitdaMargin / 100)
    };
  }, [targetValuation, selectedMultiple, timeframe, current]);

  const {
    requiredGrowthRate,
    monthlyData,
    targetEBITDA,
    requiredRevenue
  } = calculateRequiredMetrics();

  return (
    <div className="space-y-8">
      {/* Input Controls in the style of your existing cards */}
      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-xl font-bold mb-4">Valuation Goal Settings</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Target Valuation
            </label>
            <input
              type="number"
              value={targetValuation}
              onChange={(e) => setTargetValuation(Number(e.target.value))}
              className="w-full p-2 border rounded-md"
              min="0"
              step="100000"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Timeframe (months)
            </label>
            <input
              type="number"
              value={timeframe}
              onChange={(e) => setTimeframe(Number(e.target.value))}
              className="w-full p-2 border rounded-md"
              min="1"
              max="60"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Target Multiple
            </label>
            <input
              type="number"
              value={selectedMultiple}
              onChange={(e) => setSelectedMultiple(Number(e.target.value))}
              className="w-full p-2 border rounded-md"
              min="1"
              max="10"
              step="0.1"
            />
          </div>
        </div>
      </div>

      {/* Required Metrics Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-sm font-medium text-gray-500">Required Annual Growth</h3>
          <p className="text-2xl font-bold mt-2">{requiredGrowthRate.toFixed(1)}%</p>
        </div>
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-sm font-medium text-gray-500">Target EBITDA</h3>
          <p className="text-2xl font-bold mt-2">{formatCurrency(targetEBITDA)}</p>
        </div>
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-sm font-medium text-gray-500">Required Revenue</h3>
          <p className="text-2xl font-bold mt-2">{formatCurrency(requiredRevenue)}</p>
        </div>
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-sm font-medium text-gray-500">Monthly Growth Needed</h3>
          <p className="text-2xl font-bold mt-2">{(requiredGrowthRate / 12).toFixed(1)}%</p>
        </div>
      </div>

      {/* Growth Chart */}
      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-xl font-bold mb-4">Growth Projection</h2>
        <div className="h-[400px]">
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart data={monthlyData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="month"
                height={60}
                tick={{ angle: -45, textAnchor: 'end' }}
              />
              <YAxis
                yAxisId="left"
                tickFormatter={(value) => formatCurrency(value)}
              />
              <Tooltip
                formatter={(value) => [formatCurrency(value), '']}
                labelFormatter={(label) => `Month ${label}`}
              />
              <Legend />
              <Line
                type="monotone"
                dataKey="Revenue"
                stroke="#2563EB"
                strokeWidth={2}
                yAxisId="left"
                name="Revenue"
              />
              <Line
                type="monotone"
                dataKey="EBITDA"
                stroke="#16A34A"
                strokeWidth={2}
                yAxisId="left"
                name="EBITDA"
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Warning Message for High Growth Rates */}
      {requiredGrowthRate > 50 && (
        <div className="bg-red-50 border-l-4 border-red-400 p-4">
          <div className="flex">
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">High Growth Rate Required</h3>
              <div className="mt-2 text-sm text-red-700">
                The required growth rate of {requiredGrowthRate.toFixed(1)}% per year may be challenging
                to achieve. Consider adjusting your target valuation, timeframe, or exploring strategic
                acquisitions.
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// Component definitions
const ComparisonCard = ({ title, currentValue, industryAvg, icon: Icon }) => {
  const difference = currentValue - industryAvg;
  const percentageDiff = currentValue - industryAvg;

  // Determine if this is a profit metric (where higher is better)
  const isProfitMetric = title === 'Gross Profit' || title === 'EBITDA';

  // For profit metrics: green when higher, red when lower
  // For cost metrics: red when higher, green when lower
  const isPositiveOutcome = isProfitMetric ? difference > 0 : difference < 0;

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <div className="flex items-center justify-between mb-4">
        <div className="rounded-lg bg-blue-100 p-2">
          <Icon className="h-5 w-5 text-blue-600" />
        </div>
        <div className={`flex items-center space-x-1 text-sm ${
          isPositiveOutcome ? 'text-green-600' : 'text-red-600'
        }`}>
          {difference >= 0 ? <ArrowUpRight className="h-4 w-4" /> : <ArrowDownRight className="h-4 w-4" />}
          <span>{Math.abs(percentageDiff)}% vs Industry</span>
        </div>
      </div>
      <h3 className="text-sm font-medium text-gray-500">{title}</h3>
      <div className="mt-2 space-y-2">
        <div className="flex justify-between items-center">
          <span className="text-sm text-gray-600">Current</span>
          <span className="font-semibold">{currentValue}%</span>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-sm text-gray-600">Industry Avg</span>
          <span className="font-medium text-gray-500">{industryAvg}%</span>
        </div>
      </div>
    </div>
  );
};

const OperatingCostsTable = ({ costs }) => (
  <div className="overflow-x-auto">
    <table className="min-w-full divide-y divide-gray-200">
      <thead>
        <tr>
          <th className="px-4 py-2 text-left text-sm font-medium text-gray-500">Category</th>
          <th className="px-4 py-2 text-right text-sm font-medium text-gray-500">Amount</th>
          <th className="px-4 py-2 text-right text-sm font-medium text-gray-500">% of Revenue</th>
          <th className="px-4 py-2 text-right text-sm font-medium text-gray-500">vs Last Period</th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {costs.map((cost, index) => (
          <tr key={index}>
            <td className="px-4 py-2 text-sm text-gray-900">{cost.category}</td>
            <td className="px-4 py-2 text-sm text-right text-gray-900">{formatCurrency(cost.amount)}</td>
            <td className="px-4 py-2 text-sm text-right text-gray-600">{cost.percentage}%</td>
            <td className={`px-4 py-2 text-sm text-right ${
              cost.change > 0 ? 'text-red-600' : 'text-green-600'
            }`}>
              {cost.change > 0 ? '+' : ''}{cost.change}%
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const AddBackSection = ({ onAddBacksChange }) => {
  // QuickBooks linked add-backs (simulated)
  const linkedAddBacks = [
    { category: "Auto Expenses", amount: 24000, source: "QuickBooks" },
    { category: "Health Insurance", amount: 32000, source: "QuickBooks" },
    { category: "Legal Fees", amount: 15000, source: "QuickBooks" },
    { category: "Travel & Entertainment", amount: 18000, source: "QuickBooks" }
  ];

  // Provider data from overview tab
  const providers = [
    { id: 1, name: "Dr. Smith", revenue: 550000 },
    { id: 2, name: "Dr. Johnson", revenue: 450000 },
    { id: 3, name: "Dr. Williams", revenue: 600000 }
  ];

  const [selectedProvider, setSelectedProvider] = useState('');
  const [manualAddBacks, setManualAddBacks] = useState([
    { category: "Family Member Salaries", amount: 0, enabled: false },
    { category: "Other", amount: 0, enabled: false }
  ]);

  // Calculate officer compensation add-back
  const calculateOfficerAddBack = (providerId) => {
    if (!providerId) return 0;
    const provider = providers.find(p => p.id.toString() === providerId.toString());
    if (!provider) return 0;
    const marketRate = provider.revenue * 0.15; // 15% of revenue
    const currentSalary = provider.revenue * 0.175; // Current at 17.5%
    return Math.max(0, currentSalary - marketRate);
  };

  const officerAddBack = calculateOfficerAddBack(selectedProvider);

  // Calculate total add-backs
  const calculateTotal = () => {
    const linkedTotal = linkedAddBacks.reduce((sum, item) => sum + item.amount, 0);
    const manualTotal = manualAddBacks.reduce((sum, item) =>
      item.enabled ? sum + item.amount : sum, 0
    );
    const total = linkedTotal + officerAddBack + manualTotal;
    onAddBacksChange(total);
  };

  // Handle manual add-back changes
  const handleToggle = (index) => {
    const newAddBacks = [...manualAddBacks];
    newAddBacks[index].enabled = !newAddBacks[index].enabled;
    setManualAddBacks(newAddBacks);
    calculateTotal();
  };

  const handleAmountChange = (index, amount) => {
    const newAddBacks = [...manualAddBacks];
    newAddBacks[index].amount = parseFloat(amount) || 0;
    setManualAddBacks(newAddBacks);
    calculateTotal();
  };

  // Update total when any values change
  React.useEffect(() => {
    calculateTotal();
  }, [selectedProvider, manualAddBacks, linkedAddBacks]);

  return (
    <div className="bg-white rounded-lg shadow p-6 mb-6">
      <h3 className="text-lg font-semibold mb-4">EBITDA Add-Backs</h3>

      {/* QuickBooks Linked Add-backs */}
      <div className="mb-6">
        <h4 className="text-sm font-medium text-gray-700 mb-3">QuickBooks Linked Add-backs</h4>
        <div className="space-y-3">
          {linkedAddBacks.map((item, index) => (
            <div key={index} className="flex items-center justify-between">
              <span className="text-sm text-gray-600">{item.category}</span>
              <div className="flex items-center space-x-2">
                <span className="text-sm font-medium">{formatCurrency(item.amount)}</span>
                <span className="text-xs text-blue-600 bg-blue-50 px-2 py-1 rounded">
                  QuickBooks
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Officer Compensation Add-back */}
      <div className="mb-6">
        <h4 className="text-sm font-medium text-gray-700 mb-3">Officer Compensation Add-back</h4>
        <div className="flex items-center space-x-4">
          <select
            value={selectedProvider}
            onChange={(e) => setSelectedProvider(e.target.value)}
            className="flex-grow p-2 border rounded-md text-sm"
          >
            <option value="">Select Provider</option>
            {providers.map(provider => (
              <option key={provider.id} value={provider.id}>
                {provider.name}
              </option>
            ))}
          </select>
          <div className="text-right min-w-[120px]">
            <span className="text-sm font-medium">
              {selectedProvider ? formatCurrency(officerAddBack) : '-'}
            </span>
          </div>
        </div>
      </div>

      {/* Manual Add-backs */}
      <div>
        <h4 className="text-sm font-medium text-gray-700 mb-3">Manual Add-backs</h4>
        <div className="space-y-4">
          {manualAddBacks.map((item, index) => (
            <div key={index} className="flex items-center space-x-4">
              <input
                type="checkbox"
                checked={item.enabled}
                onChange={() => handleToggle(index)}
                className="rounded border-gray-300"
              />
              <span className="text-sm flex-grow">{item.category}</span>
              <input
                type="number"
                value={item.amount}
                onChange={(e) => handleAmountChange(index, e.target.value)}
                disabled={!item.enabled}
                className="w-32 px-2 py-1 border rounded text-right disabled:bg-gray-100"
                placeholder="0"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const LTMMetrics = ({ chartData, totalAddBacks }) => {
  const ltmRevenue = React.useMemo(() =>
    chartData.reduce((sum, month) => sum + month.Revenue, 0),
    [chartData]
  );

  const baseEbitda = React.useMemo(() =>
    chartData.reduce((sum, month) => sum + month.EBITDA, 0),
    [chartData]
  );

  const adjustedEbitda = baseEbitda + totalAddBacks;

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
      <div className="bg-white rounded-lg shadow p-6">
        <h3 className="text-sm font-medium text-gray-500">LTM Revenue</h3>
        <p className="text-2xl font-bold mt-2">{formatCurrency(ltmRevenue)}</p>
      </div>
      <div className="bg-white rounded-lg shadow p-6">
        <h3 className="text-sm font-medium text-gray-500">LTM EBITDA (Base)</h3>
        <p className="text-2xl font-bold mt-2">{formatCurrency(baseEbitda)}</p>
      </div>
      <div className="bg-white rounded-lg shadow p-6">
        <h3 className="text-sm font-medium text-gray-500">LTM EBITDA (Adjusted)</h3>
        <p className="text-2xl font-bold mt-2">{formatCurrency(adjustedEbitda)}</p>
      </div>
    </div>
  );
};

const ValuationSection = ({ ltmEbitda }) => {
  const [selectedMultiple, setSelectedMultiple] = React.useState(5);
  const valuationRange = {
    low: ltmEbitda * (selectedMultiple - 0.5),
    high: ltmEbitda * (selectedMultiple + 0.5)
  };

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h2 className="text-xl font-bold mb-4">Practice Valuation</h2>
      <div className="space-y-6">
        <div>
          <div className="flex justify-between items-center mb-2">
            <label className="text-sm font-medium text-gray-700">
              EBITDA Multiple
            </label>
            <span className="text-sm font-semibold text-blue-600">
              {selectedMultiple}x
            </span>
          </div>
          <input
            type="range"
            min={3}
            max={10}
            step={0.1}
            value={selectedMultiple}
            onChange={(e) => setSelectedMultiple(Number(e.target.value))}
            className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
          />
          <div className="flex justify-between text-xs text-gray-500 mt-1">
            <span>3x</span>
            <span>6.5x</span>
            <span>10x</span>
          </div>
        </div>

        <div className="bg-blue-50 p-4 rounded-lg">
          <div className="text-sm text-blue-700 mb-2">Estimated Practice Value</div>
          <div className="text-3xl font-bold text-blue-900">
            {formatCurrency(valuationRange.low)} - {formatCurrency(valuationRange.high)}
          </div>
          <div className="text-sm text-blue-600 mt-1">
            Based on {selectedMultiple}x EBITDA multiple
          </div>
        </div>
      </div>
    </div>
  );
};

const ValuationTab = () => {
  const [totalAddBacks, setTotalAddBacks] = useState(0);

  const chartData = React.useMemo(() => Array.from({ length: 12 }, (_, i) => ({
    month: new Date(2024, i).toLocaleString('default', { month: 'short' }),
    Revenue: 100000 + (i * 5000) + (Math.random() * 10000),
    EBITDA: 25000 + (i * 1250) + (Math.random() * 2500)
  })), []);

  const adjustedLtmEbitda = React.useMemo(() =>
    chartData.reduce((sum, month) => sum + month.EBITDA, 0) + totalAddBacks,
    [chartData, totalAddBacks]
  );

  return (
    <div className="space-y-8">
      <LTMMetrics chartData={chartData} totalAddBacks={totalAddBacks} />
      <AddBackSection onAddBacksChange={setTotalAddBacks} />

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-bold mb-4">EBITDA History & Projections</h2>
          <div className="h-[400px]">
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="month"
                  height={60}
                  tick={{ angle: -45, textAnchor: 'end' }}
                />
                <YAxis
                  yAxisId="left"
                  tickFormatter={(value) => formatCurrency(value)}
                />
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  tickFormatter={(value) => formatCurrency(value)}
                />
                <Tooltip
                  formatter={(value) => [formatCurrency(value), '']}
                  labelFormatter={(label) => `Month: ${label}`}
                />
                <Legend />
                <Bar
                  dataKey="EBITDA"
                  fill="#4F46E5"
                  yAxisId="right"
                  name="EBITDA"
                />
                <Line
                  type="monotone"
                  dataKey="Revenue"
                  stroke="#2563EB"
                  strokeWidth={2}
                  dot={false}
                  yAxisId="left"
                  name="Revenue"
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
        <ValuationSection ltmEbitda={adjustedLtmEbitda} />
      </div>
    </div>
  );
};

const RevenueBreakdown = () => {
  const [providers, setProviders] = useState([
    { id: 1, name: "Dr. Smith", revenue: 550000, patients: 1700, revenuePerPatient: 323 },
    { id: 2, name: "Dr. Johnson", revenue: 450000, patients: 1500, revenuePerPatient: 300 },
    { id: 3, name: "Dr. Williams", revenue: 600000, patients: 1800, revenuePerPatient: 333 }
  ]);

  const totalRevenue = providers.reduce((sum, provider) => sum + provider.revenue, 0);
  const totalPatients = providers.reduce((sum, provider) => sum + provider.patients, 0);
  const averageRevenuePerPatient = totalRevenue / totalPatients;

  return (
    <div className="bg-white rounded-lg shadow p-6 mb-8">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-bold text-gray-900">Revenue by Provider</h2>
        <div className="flex items-center space-x-4">
          <div className="text-right">
            <div className="text-sm text-gray-500">Total Revenue</div>
            <div className="text-2xl font-bold text-blue-600">{formatCurrency(totalRevenue)}</div>
          </div>
          <div className="text-right">
            <div className="text-sm text-gray-500">Revenue Per Patient</div>
            <div className="text-lg font-semibold text-gray-900">{formatCurrency(averageRevenuePerPatient)}</div>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full">
          <thead>
            <tr className="border-b border-gray-200">
              <th className="px-4 py-2 text-left text-sm font-medium text-gray-500">Provider</th>
              <th className="px-4 py-2 text-right text-sm font-medium text-gray-500">Revenue</th>
              <th className="px-4 py-2 text-right text-sm font-medium text-gray-500">% of Total</th>
              <th className="px-4 py-2 text-right text-sm font-medium text-gray-500">Patients</th>
              <th className="px-4 py-2 text-right text-sm font-medium text-gray-500">Revenue/Patient</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {providers.map((provider) => (
              <tr key={provider.id}>
                <td className="px-4 py-3 text-sm text-gray-900">{provider.name}</td>
                <td className="px-4 py-3 text-sm text-right text-gray-900">
                  {formatCurrency(provider.revenue)}
                </td>
                <td className="px-4 py-3 text-sm text-right text-gray-600">
                  {((provider.revenue / totalRevenue) * 100).toFixed(1)}%
                </td>
                <td className="px-4 py-3 text-sm text-right text-gray-900">
                  {provider.patients.toLocaleString()}
                </td>
                <td className="px-4 py-3 text-sm text-right text-gray-900">
                  {formatCurrency(provider.revenuePerPatient)}
                </td>
              </tr>
            ))}
            {/* Summary row */}
            <tr className="bg-gray-50 font-medium">
              <td className="px-4 py-3 text-sm text-gray-900">Total</td>
              <td className="px-4 py-3 text-sm text-right text-gray-900">
                {formatCurrency(totalRevenue)}
              </td>
              <td className="px-4 py-3 text-sm text-right text-gray-900">100%</td>
              <td className="px-4 py-3 text-sm text-right text-gray-900">
                {totalPatients.toLocaleString()}
              </td>
              <td className="px-4 py-3 text-sm text-right text-gray-900">
                {formatCurrency(averageRevenuePerPatient)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Revenue Distribution Bar */}
      <div className="mt-6">
        <div className="h-4 flex rounded-full overflow-hidden">
          {providers.map((provider, index) => {
            const width = (provider.revenue / totalRevenue) * 100;
            const colors = ['bg-blue-500', 'bg-green-500', 'bg-purple-500', 'bg-yellow-500'];
            return (
              <div
                key={provider.id}
                className={`${colors[index % colors.length]} hover:opacity-80 transition-opacity`}
                style={{ width: `${width}%` }}
                title={`${provider.name}: ${formatCurrency(provider.revenue)}`}
              />
            );
          })}
        </div>
        <div className="mt-2 flex justify-between text-xs text-gray-500">
          {providers.map((provider, index) => (
            <div key={provider.id} className="flex items-center">
              <div className={`w-3 h-3 rounded-full mr-1 ${
                ['bg-blue-500', 'bg-green-500', 'bg-purple-500', 'bg-yellow-500'][index % 4]
              }`} />
              {provider.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const FinancialOverview = () => {
  const operatingCosts = [
    { category: 'Staff Payroll', amount: 320000, percentage: 20, change: 2.5 },
    { category: 'Doctor Payroll', amount: 272500, percentage: 17.5, change: 1.2 },
    { category: 'Rent', amount: 80000, percentage: 5, change: 0 },
    { category: 'Supplies', amount: 64000, percentage: 4, change: -1.5 },
    { category: 'Marketing', amount: 48000, percentage: 3, change: 0.8 },
    { category: 'Insurance', amount: 40000, percentage: 2.5, change: 0.3 },
    { category: 'Utilities', amount: 24000, percentage: 1.5, change: 1.1 },
  ];

  return (
    <div className="space-y-8">
       <RevenueBreakdown />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
        <ComparisonCard
          title="Gross Profit"
          currentValue={70}
          industryAvg={65}
          icon={DollarSign}
        />
        <ComparisonCard
          title="Staff Payroll"
          currentValue={20}
          industryAvg={22}
          icon={Users}
        />
        <ComparisonCard
          title="Doctor Payroll"
          currentValue={17.5}
          industryAvg={15}
          icon={Users}
        />
        <ComparisonCard
          title="Rent"
          currentValue={5}
          industryAvg={6}
          icon={Building}
        />
        <ComparisonCard
          title="EBITDA"
          currentValue={25}
          industryAvg={20}
          icon={BarChart}
        />
      </div>

    <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-xl font-bold mb-4">Operating Costs Breakdown</h2>
        <OperatingCostsTable costs={operatingCosts} />
      </div>
    </div>
  );
};

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState('overview');
  const [selectedPeriod, setSelectedPeriod] = useState('12m');

  return (
    <div className="p-8 max-w-7xl mx-auto">
      <div className="flex justify-between items-center mb-8">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Practice Analytics</h1>
          <p className="text-gray-500">Track your practice's performance and metrics</p>
        </div>
        <div className="flex space-x-2">
          <select
            value={selectedPeriod}
            onChange={(e) => setSelectedPeriod(e.target.value)}
            className="bg-white border border-gray-300 rounded-md px-3 py-2 text-sm"
          >
            <option value="3m">Last 3 months</option>
            <option value="6m">Last 6 months</option>
            <option value="12m">Last 12 months</option>
            <option value="ytd">Year to date</option>
          </select>
        </div>
      </div>

      {/* Simple Tab Navigation */}
      <div className="mb-6">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8">
  {['overview', 'valuation', 'goals'].map((tab) => (
    <button
      key={tab}
      onClick={() => setActiveTab(tab)}
      className={`
        py-4 px-1 border-b-2 font-medium text-sm
        ${activeTab === tab
          ? 'border-blue-500 text-blue-600'
          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}
      `}
    >
      {tab.charAt(0).toUpperCase() + tab.slice(1)}
    </button>
  ))}
</nav>
        </div>
      </div>

      {/* Tab Content */}
      <div className="mt-6">
  {activeTab === 'overview' && <FinancialOverview />}
  {activeTab === 'valuation' && <ValuationTab />}
  {activeTab === 'goals' && <ValuationGoals currentMetrics={{
    total_revenue: 1600000,
    ebitda_percentage: 25,
    staff_payroll_percentage: 20,
    doctor_payroll_percentage: 17.5,
    rent_percentage: 5,
    gross_profit_percentage: 70
  }} />}
</div>
    </div>
  );
};

export default Dashboard;

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import Login from './components/Login';
import Signup from './components/Signup';
import Dashboard from './components/Dashboard';
import { Analytics } from '@vercel/analytics/react';
import GoogleAnalytics from './components/GoogleAnalytics';
import { TermsPage, PrivacyPage } from './components/LegalPages';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isDemoUser, setIsDemoUser] = useState(false);

  useEffect(() => {
    // Check for authentication
    const token = localStorage.getItem('token');
    const demoUser = localStorage.getItem('demo_user');

    if (token) {
      setIsAuthenticated(true);
      setIsDemoUser(false);
    } else if (demoUser) {
      setIsAuthenticated(true);
      setIsDemoUser(true);
    }
  }, []);

  const handleLogin = (token) => {
    localStorage.setItem('token', token);
    setIsAuthenticated(true);
    setIsDemoUser(false);
  };

  const handleDemoLogin = (demoUser) => {
    localStorage.setItem('demo_user', JSON.stringify(demoUser));
    setIsAuthenticated(true);
    setIsDemoUser(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('demo_user');
    setIsAuthenticated(false);
    setIsDemoUser(false);
  };

  return (
    <Router>
      <GoogleAnalytics />  {/* Google Analytics */}
      <Analytics />       {/* Vercel Analytics */}
      <Routes>
        {/* Public routes */}
        <Route path="/" element={<LandingPage />} />
        <Route
          path="/signup"
          element={
            isAuthenticated ? (
              <Navigate to="/dashboard" replace />
            ) : (
              <Signup onSignup={handleDemoLogin} />
            )
          }
        />
        <Route
          path="/login"
          element={
            isAuthenticated ? (
              <Navigate to="/dashboard" replace />
            ) : (
              <Login onLogin={handleLogin} />
            )
          }
        />

        {/* Legal routes - publicly accessible */}
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />

        {/* Protected route */}
        <Route
          path="/dashboard"
          element={
            isAuthenticated ? (
              <Dashboard onLogout={handleLogout} isDemoUser={isDemoUser} />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
import React from 'react';
import ReactGA from 'react-ga4';

export const TermsPage = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="px-6 py-4 border-b border-gray-200">
            <h1 className="text-2xl font-bold text-gray-900">Terms of Service</h1>
          </div>
          <div className="px-6 py-4">
            <p className="text-sm text-gray-500 mb-6">Last Updated: {new Date().toLocaleDateString()}</p>

            <h2 className="text-xl font-semibold mt-6 text-gray-900">1. Introduction</h2>
            <p className="mt-2 text-gray-600">Welcome to Optimeyed ("we," "our," or "us"). This website and service is currently in proof-of-concept (beta) stage. By using our service, you agree to these Terms of Service.</p>

            <h2 className="text-xl font-semibold mt-6 text-gray-900">2. Service Description</h2>
            <p className="mt-2 text-gray-600">We provide financial analytics tools for optometry practices through a web-based dashboard. This service is currently in beta testing and may:</p>
            <ul className="list-disc pl-6 mt-2 text-gray-600">
              <li>Contain bugs or errors</li>
              <li>Change significantly without notice</li>
              <li>Be discontinued at any time</li>
              <li>Have limited or intermittent availability</li>
            </ul>

            <h2 className="text-xl font-semibold mt-6 text-gray-900">3. Beta Program</h2>
            <p className="mt-2 text-gray-600">By using this beta service, you acknowledge:</p>
            <ul className="list-disc pl-6 mt-2 text-gray-600">
              <li>The service is provided "as is" without warranty of any kind</li>
              <li>Features and functionality may change</li>
              <li>We make no guarantees about service availability or data accuracy</li>
              <li>This is a proof of concept and not intended for production use</li>
            </ul>

            <h2 className="text-xl font-semibold mt-6 text-gray-900">4. Contact</h2>
            <p className="mt-2 text-gray-600">Questions about these terms can be sent to: peter@optimeyed.com</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PrivacyPage = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="px-6 py-4 border-b border-gray-200">
            <h1 className="text-2xl font-bold text-gray-900">Privacy Policy</h1>
          </div>
          <div className="px-6 py-4">
            <p className="text-sm text-gray-500 mb-6">Last Updated: {new Date().toLocaleDateString()}</p>

            <h2 className="text-xl font-semibold mt-6 text-gray-900">1. Information We Collect</h2>
            <p className="mt-2 text-gray-600">We collect:</p>
            <ul className="list-disc pl-6 mt-2 text-gray-600">
              <li>Email addresses when you join our waitlist</li>
              <li>Usage data (pages visited, time spent on site)</li>
              <li>Analytics data through Google Analytics</li>
            </ul>

            <h2 className="text-xl font-semibold mt-6 text-gray-900">2. How We Use Information</h2>
            <p className="mt-2 text-gray-600">We use this information to:</p>
            <ul className="list-disc pl-6 mt-2 text-gray-600">
              <li>Communicate updates about our service</li>
              <li>Understand user interest and behavior</li>
              <li>Improve our product</li>
            </ul>

            <h2 className="text-xl font-semibold mt-6 text-gray-900">3. Your Rights</h2>
            <p className="mt-2 text-gray-600">You may:</p>
            <ul className="list-disc pl-6 mt-2 text-gray-600">
              <li>Request to be removed from our waitlist</li>
              <li>Ask what information we have about you</li>
              <li>Request deletion of your information</li>
            </ul>

            <h2 className="text-xl font-semibold mt-6 text-gray-900">4. Contact</h2>
            <p className="mt-2 text-gray-600">Privacy questions can be sent to: peter@optimeyed.com</p>
          </div>
        </div>
      </div>
    </div>
  );
};
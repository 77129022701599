import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Box, Container, CircularProgress, Alert } from '@mui/material';

function Login({ onLogin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [wsStatus, setWsStatus] = useState('');

  useEffect(() => {
    // Check WebSocket status
    const checkWsStatus = () => {
      if (window.WebSocket) {
        const ws = new WebSocket('ws://localhost:8000/ws');  // Adjust the URL as needed
        ws.onopen = () => setWsStatus('Connected');
        ws.onerror = () => setWsStatus('Error');
        return () => ws.close();
      } else {
        setWsStatus('Not Supported');
      }
    };
    checkWsStatus();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      console.log('Attempting login...');
      const response = await fetch('http://localhost:8000/token', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams({
          username: email,
          password: password,
        }),
      });

      console.log('Response received, status:', response.status);
      const data = await response.json();
      console.log('Response data:', data);

      if (response.ok) {
        console.log('Login successful, storing token...');
        const expiresIn = data.expires_in ? Date.now() + data.expires_in * 1000 : null;
        localStorage.setItem('token', data.access_token);
        if (expiresIn) {
          localStorage.setItem('token_expiration', expiresIn);
        }
        console.log('Token stored, calling onLogin...');
        onLogin(data.access_token);
      } else {
        console.error('Login failed. Response:', data);
        setError(data.detail || 'Login failed. Please check your credentials and try again.');
      }
    } catch (error) {
      console.error('Login error:', error);
      if (error.message.includes('message channel closed')) {
        setError('A browser extension might be interfering with the login process. Try disabling extensions or use an incognito window.');
      } else {
        setError('An error occurred. Please check your connection and try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%' }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
          {wsStatus && (
            <Alert severity="info" sx={{ mt: 2 }}>
              WebSocket Status: {wsStatus}
            </Alert>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Sign In'}
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default Login;
import { useState } from 'react';
import { BarChart2, Lock, DollarSign, TrendingUp, ChartBar, Target } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom'

const MetricComparisonCard = ({ title, metric, industry, trend }) => (
  <div className="bg-white rounded-lg shadow-md p-6 border border-gray-100 transform transition-transform hover:scale-105">
    <h4 className="text-lg font-semibold mb-3">{title}</h4>
    <div className="flex justify-between items-center mb-2">
      <span className="text-gray-600">Your Practice</span>
      <span className="text-xl font-bold text-blue-600">{metric}</span>
    </div>
    <div className="flex justify-between items-center mb-3">
      <span className="text-gray-600">Industry Avg</span>
      <span className="text-lg text-gray-700">{industry}</span>
    </div>
    <div className={`flex items-center ${trend >= 0 ? 'text-green-600' : 'text-red-600'}`}>
      <TrendingUp size={16} className={trend >= 0 ? '' : 'rotate-180'} />
      <span className="ml-1 text-sm">{Math.abs(trend)}% vs Industry</span>
    </div>
  </div>
);

export default function LandingPage() {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: '', type: 'success' });
  const navigate = useNavigate();

  const handleJoinWaitlist = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch(process.env.REACT_APP_GOOGLE_SCRIPT_URL, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type: 'waitlist',
          email,
          timestamp: new Date().toISOString(),
          source: window.location.hostname
        }),
      });

      setAlert({
        show: true,
        message: "Thanks for joining our waitlist! We'll be in touch soon.",
        type: 'success'
      });
      setEmail('');

    } catch (error) {
      setAlert({
        show: true,
        message: 'Something went wrong. Please try again.',
        type: 'error'
      });
    } finally {
      setIsLoading(false);
      setTimeout(() => setAlert({ show: false, message: '', type: 'success' }), 5000);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-gray-50">
      {/* Hero Section */}
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 pt-16 pb-12">
        <div className="text-center">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            Financial Intelligence for
            <span className="text-blue-600"> Optometry Practices</span>
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-600 max-w-2xl mx-auto">
            Make data-driven decisions with real-time financial insights. Know your practice's true value,
            benchmark against industry standards, and identify growth opportunities.
          </p>
          <div className="mt-8 flex items-center justify-center gap-x-6">
            <button
              onClick={() => navigate('/signup')}
              className="rounded-md bg-blue-600 px-6 py-3 text-lg font-semibold text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600"
            >
              Try Demo Dashboard
            </button>
            <button
              onClick={() => navigate('/login')}
              className="rounded-md bg-white px-6 py-3 text-lg font-semibold text-blue-600 shadow-sm border border-blue-600 hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600"
            >
              Sign In
            </button>
          </div>
        </div>
      </div>

      {/* Practice Valuation Section */}
      <div className="bg-white py-16">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900">Know Your Practice's Value</h2>
            <p className="mt-4 text-lg text-gray-600">
              Connect with QuickBooks to automatically sync your financial data and get instant insights
              into your practice's performance and value. Our dashboard updates in real-time as your
              practice grows.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
            <MetricComparisonCard
              title="EBITDA Margin"
              metric="25.4%"
              industry="20.0%"
              trend={5.4}
            />
            <MetricComparisonCard
              title="Gross Profit"
              metric="70%"
              industry="65%"
              trend={5.0}
            />
            <MetricComparisonCard
              title="Staff Expense"
              metric="20%"
              industry="22%"
              trend={2.0}
            />
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div id="features" className="py-16 bg-gray-50">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            <div className="flex flex-col items-start">
              <div className="rounded-lg bg-blue-100 p-3">
                <ChartBar className="h-6 w-6 text-blue-600" />
              </div>
              <h3 className="mt-4 text-lg font-semibold text-gray-900">Industry Benchmarking</h3>
              <p className="mt-2 text-gray-600">
                Compare your performance against similar practices. Track key metrics like gross profit,
                staff efficiency, and profit margins.
              </p>
            </div>
            <div className="flex flex-col items-start">
              <div className="rounded-lg bg-blue-100 p-3">
                <Target className="h-6 w-6 text-blue-600" />
              </div>
              <h3 className="mt-4 text-lg font-semibold text-gray-900">Practice Valuation</h3>
              <p className="mt-2 text-gray-600">
                Get real-time practice valuation based on EBITDA multiples. Track your practice's value
                growth over time.
              </p>
            </div>
            <div className="flex flex-col items-start">
              <div className="rounded-lg bg-blue-100 p-3">
                <Lock className="h-6 w-6 text-blue-600" />
              </div>
              <h3 className="mt-4 text-lg font-semibold text-gray-900">Secure Integration</h3>
              <p className="mt-2 text-gray-600">
                Bank-level security for your financial data with encrypted QuickBooks connection.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Waitlist Section */}
      <div className="bg-blue-600 py-12">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Ready to understand your true practice value?
            </h2>
            <p className="mt-4 text-lg text-blue-100">
              Join the waitlist for early access and special launch pricing.
            </p>
            <form onSubmit={handleJoinWaitlist} className="mt-6 flex max-w-md mx-auto gap-x-4">
              <input
                type="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={isLoading}
                className="min-w-0 flex-auto rounded-md border-0 bg-white/10 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white placeholder:text-blue-100 disabled:opacity-50"
                placeholder="Enter your email"
              />
              <button
                type="submit"
                disabled={isLoading}
                className="flex-none rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-blue-600 shadow-sm hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white disabled:opacity-50"
              >
                {isLoading ? 'Adding...' : 'Join Waitlist'}
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* Alert */}
      {alert.show && (
        <div className={`fixed bottom-4 right-4 p-4 rounded-lg shadow-lg ${
          alert.type === 'success'
            ? 'bg-green-50 border border-green-200 text-green-800'
            : 'bg-red-50 border border-red-200 text-red-800'
        }`}>
          <p className="font-semibold">{alert.type === 'success' ? 'Success!' : 'Error'}</p>
          <p>{alert.message}</p>
        </div>
      )}
       <footer className="bg-white border-t border-gray-200">
        <div className="max-w-6xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <div className="flex justify-center space-x-6">
            <Link
              to="/terms"
              className="text-sm text-gray-500 hover:text-gray-900 transition duration-150"
              onClick={() => {
                ReactGA.event({
                  category: 'Legal',
                  action: 'View Terms',
                });
              }}
            >
              Terms of Service
            </Link>
            <Link
              to="/privacy"
              className="text-sm text-gray-500 hover:text-gray-900 transition duration-150"
              onClick={() => {
                ReactGA.event({
                  category: 'Legal',
                  action: 'View Privacy Policy',
                });
              }}
            >
              Privacy Policy
            </Link>
          </div>
        </div>
      </footer>
    </div>
  );
}
import { useEffect } from 'react';
import ReactGA from 'react-ga4';

const GoogleAnalytics = () => {
  useEffect(() => {
    // Your actual GA4 Measurement ID
    ReactGA.initialize('G-4TQNGHM6J7');

    // Send initial pageview
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname
    });

    // Track page changes if using React Router
    const handleLocationChange = () => {
      ReactGA.send({
        hitType: 'pageview',
        page: window.location.pathname
      });
    };

    window.addEventListener('popstate', handleLocationChange);
    return () => window.removeEventListener('popstate', handleLocationChange);
  }, []);

  return null;
};

export default GoogleAnalytics;